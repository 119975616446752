import React from "react";
const Contact = () => {
  return (
    <div className="flex min-h-screen w-full flex-wrap items-center justify-center">
      <div className="w-full rounded-xl p-12 shadow-2xl shadow-blue-200 mx-auto md:w-8/12 lg:w-5/12 bg-white">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-9">
            <div className="text-center lg:text-left">
              <h2 className="text-2xl font-bold text-zinc-700">
                Dr. Nitin Kulkarni
              </h2>
              <p className="mt-2 text-l font-semibold text-zinc-700">
                Faculty Coordinator
              </p>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-8 text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Email</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">
                  nitinkulkarni@acropolis.in
                </p>
              </div>
            </div>

            {/* <div className="mt-6 grid grid-cols-3 gap-8  text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Phone</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">9827253854</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="w-full rounded-xl p-12 shadow-2xl mx-auto shadow-blue-200 md:w-8/12 lg:w-5/12 bg-white">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-9">
            <div className="text-center lg:text-left">
              <h2 className="text-2xl font-bold text-zinc-700">
                Siddharth Jain
              </h2>
              <p className="mt-2 text-l font-semibold text-zinc-700">
                Student Coordinator
              </p>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-8 text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Email</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">
                  siddharthjain.ca21@acropolis.in
                </p>
              </div>
            </div>

            {/* <div className="mt-6 grid grid-cols-3 gap-8  text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Phone</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">7898210160</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="w-full rounded-xl p-12 shadow-2xl mx-auto shadow-blue-200 md:w-8/12 lg:w-5/12 bg-white">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-9">
            <div className="text-center lg:text-left">
              <h2 className="text-2xl font-bold text-zinc-700">
                Abhishek Kumar
              </h2>
              <p className="mt-2 text-l font-semibold text-zinc-700">
                Student Coordinator
              </p>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-8 text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Email</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">
                  abhishekkumar.ca21@acropolis.in
                </p>
              </div>
            </div>

            {/* <div className="mt-6 grid grid-cols-3 gap-8  text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Phone</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">9955357820</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="w-full rounded-xl p-12 shadow-2xl mx-auto shadow-blue-200 md:w-8/12 lg:w-5/12 bg-white">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          <div className="col-span-1 lg:col-span-9">
            <div className="text-center lg:text-left">
              <h2 className="text-2xl font-bold text-zinc-700">
                Sarthak Baderiya
              </h2>
              <p className="mt-2 text-l font-semibold text-zinc-700">
                Student Coordinator
              </p>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-8 text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Email</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">
                 sarthakbaderiya.ca21@acropolis.in
                </p>
              </div>
            </div>

            {/* <div className="mt-6 grid grid-cols-3 gap-8  text-center items-center lg:text-left">
              <div>
                <p className="font-bold text-zinc-700">Phone</p>
              </div>

              <div>
                <p className="text-m font-semibold text-zinc-700">8269690492</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
